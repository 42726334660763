import downloadimage from "../../images/dimage.png";
// import Button from "../Button";
import Navbar from "../Navbar/Navbar";
import React, { useState, createRef } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import "./Download.css";
const Download = ({ user }) => {
	let linkRef = React.createRef();
	let Months = [
		"All",
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	let counties = [
		"All Counties",
		"Nakuru",
		"Kitui",
		"Homa Bay",
		"Siaya",
		"Tharaka Nithi",
		"Embu",
		"Meru",
		"Migori",
	];
	let approvals = [
		"All Statuses",
		"Approved",
		"Not Verified",
		"Pending",
		"Action Needed",
		"Rejected",
	];
	let years = ["All Years", "2022", "2023","2024","2025"];
	//if No Data return from server
	function showErrorMessage() {
		setshowerror(true);
	}
	const [showerror, setshowerror] = useState(false);
	const [selectedMonths, setselectedMonths] = useState(null);
	const [selectedYear, setselectedYear] = useState(null);
	const [selectedcounty, setselectedcounty] = useState(null);
	const [theapproval, settheapproval] = useState(null);
	const [download, setdownload] = useState("Download Excel Sheet");
	const styles = ["months-dialog1", "months-dialog2", "months-dialog3"];
	function downloadExcel() {
		if (selectedMonths && user) {
			setdownload("Preparing Data...");
			let county;
			let month;
			let approval;
			let year;
			if (selectedcounty == "All Counties") {
				county = null;
			} else {
				county = selectedcounty;
			}
			if (selectedMonths == "All") {
				month = null;
			} else {
				month = selectedMonths;
			}
			if (theapproval == "All Statuses") {
				approval = null;
			} else {
				approval = theapproval;
			}
			if (selectedYear == "All Years") {
				year = null;
			} else {
				year = selectedYear;
			}
			let body = {
				month: month,
				county: county,
				approval: approval,
				year: year,
			};
			fetch("/api/v1/downloadexcel", {
				headers: {
					"Content-Type": "application/json",
				},
				credentials: "include",
				method: "POST",
				body: JSON.stringify(body),
			})
				.then((res) => {
					return res.blob();
				})
				.then((blob) => {
					let contenttype = blob.type;
					if (contenttype && contenttype.indexOf("application/json") !== -1) {
						setdownload("Download");
						showErrorMessage();
					} else {
						setshowerror(false)
						const href = window.URL.createObjectURL(blob);
						const a = document.createElement("a");
						a.download = selectedMonths + " Data.xlsx";
						a.href = href;
						document.body.appendChild(a);
						a.click();
						a.href = "";
						setdownload("Download");
					}
				})
				.catch((err) =>  Error(err));
		}
	}
	function downloadReport(){
		fetch("/api/v1/downloadreport", {
			method: "GET"
		})
			.then((res) => {
				return res.blob();
			})
			.then((blob) => {
				let contenttype = blob.type;
				if (contenttype && contenttype.indexOf("application/json") !== -1) {
					setdownload("Download");
					showErrorMessage();
				} else {
					const href = window.URL.createObjectURL(blob);
					const a = document.createElement("a");
					a.download = "Quantitative Report.xlsx";
					a.href = href;
					document.body.appendChild(a);
					a.click();
					a.href = "";
				}
			})
			.catch((err) =>  Error(err));
	}
	function selectCounty(e){
		setselectedcounty(e.target.value)
	}
	function selectMonth(e){
		setselectedMonths(e.target.value)
	}
	function selectStatus(e){
		settheapproval(e.target.value)
	}
	function selectYear(e){
		setselectedYear(e.target.value)
	}
	return (
		<div>
			<Navbar user={user} />
			<div className='analysis-body first-element'>
				<div className='downloads-header'>
					<h1>Resources Downloads.</h1>
				</div>
			</div>
			<div className='downloads-container'>
				<div className='download-content'>
					<div className='d-content'>
						<h1>Data Export.</h1>
						<a ref={linkRef}></a>
						<hr className='download-hr' />
						<div className='download-options'>
							{user && user.user.scope == "1" && (
								<FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="medium" className="formctrl">
								<InputLabel id="statefilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Select County</InputLabel>
									<Select
									  labelId="statefilter"
									  id="yearfilterid"
									  value={selectedcounty}
									  label="Select County"
									  onChange={selectCounty}
									  sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
									{counties.map((c,i)=>(
									  <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
									))}
									</Select>
						 		 </FormControl>
							)}
							<FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="medium" className="formctrl">
								<InputLabel id="statefilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Select Year</InputLabel>
									<Select
									  labelId="statefilter"
									  id="yearfilterid"
									  value={selectedYear}
									  label="Select Year"
									  onChange={selectYear}
									  sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
									{years.map((c,i)=>(
									  <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
									))}
									</Select>
						 		 </FormControl>
								  <FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="medium" className="formctrl">
								<InputLabel id="statefilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Select Month</InputLabel>
									<Select
									  labelId="statefilter"
									  id="yearfilterid"
									  value={selectedMonths}
									  label="Select Month"
									  onChange={selectMonth}
									  sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
									{Months.map((c,i)=>(
									  <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
									))}
									</Select>
						 		 </FormControl>
								  <FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="medium" className="formctrl">
								<InputLabel id="statefilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Select Approval</InputLabel>
									<Select
									  labelId="statefilter"
									  id="yearfilterid"
									  value={theapproval}
									  label="Select Approval"
									  onChange={selectStatus}
									  sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
									{approvals.map((c,i)=>(
									  <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
									))}
									</Select>
						 		 </FormControl>
									<Button  sx={{fontFamily:'Cera Pro'}} onClick={(e) => {
									downloadExcel();
								}} >{download}</Button>
						</div>
						{showerror && (
                  <Alert severity="warning">Download Error: Your query didnt match any data in the server, try
				  again with different parameters</Alert>
					)}
						<h1>Reports Export.</h1>
						<hr className='download-hr' />

						<div>
						{/* <Button
								 sx={{fontFamily:'Cera Pro'}}
								onClick={(e) => {
									downloadReport();
								}} >
								Generate Quantitative Report
							</Button>						 */}
							</div>
					</div>
					
				</div>
			</div>
		</div>
	);
};

export default Download;
